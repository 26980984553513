import { useRef, useEffect } from "react";
import { API } from 'aws-amplify';
import { configureWidget } from "../utils/widget-configuration";
import { configureFeatureTable } from "../utils/table-configuration";

import * as urlUtils from "@arcgis/core/core/urlUtils";
import * as mapConfig from '../utils/map-configuration';
import * as settings from '../utils/feature-settings';

import MapView from "@arcgis/core/views/MapView";
import esriConfig from "@arcgis/core/config";
import Graphic from "@arcgis/core/Graphic";
import Map from "@arcgis/core/Map";

const Maps = () => {
  const mapDiv = useRef(null);

  useEffect(() => {
    if (mapDiv.current) {
      esriConfig.apiKey = "AAPTxy8BH1VEsoebNVZXo8HurGlXnEyDPcNGHjaVinQcp8YtMaILFTZQ5G8d516yA_G30I3kxD_qGC9T7wkU74G06eeL9ea-CSXSPui_fZ3hTGYGgBeps8qIu2N9vD-4w0yDXzmgMN6ieuKeaRpj_CqmMkcc8SpY_PVN-XP2rw9iNFQi6RcZj1u5zDDvgcIMnt_VE_6C5WWCDySP4zWjcaGcVwLomLd2M9oF_eeRW-urK-e6In1DFLCYgU3JVHOBy98pnHXVe71psffAXBP10T0FTQ3LI9jcF8E_njNcla7oCLU.AT1_edhdTFMA";

      //--------- Initial map configuration ---------//
      const map = new Map({
        basemap: "arcgis-dark-gray"
      });

      let urlParams = urlUtils.urlToObject(window.location.href).query;
      let zoomConfig = settings.getFeatureZoom(urlParams);

      const view = new MapView({
        map,
        center: [zoomConfig[0], zoomConfig[1]],
        zoom: zoomConfig[2],
        container: mapDiv.current,
        constraints: {
          snapToZoom: false
        }
      });

      //--------- Hazard layer configuration ---------//
      const activeHazardLayer = mapConfig.configureActiveHazardLayer();
      const nonActiveHazardLayer = mapConfig.configureNonActiveHazardLayer();
      const outStatus = ['Out', 'Extinguished', 'Turned Over', 'Assistance Ended', 'Mutual Aid - Assistance Ended', 'Mutual Aid - Extinguished'];

      API.get('hazNotificationsAPI', '/alerts')
        .then(response => {
          let graphics = [];
          let outGraphics = [];

          response.forEach(alert => {
            const point = {
              type: "point",
              longitude: alert.longitude,
              latitude: alert.latitude
            };

            alert.hazard_type = mapConfig.updateHazardStatus(alert);

            const pointGraphic = new Graphic({
              geometry: point,
              attributes:
              {
                aoi_name: alert.aoi_name,
                hazard_label: alert.hazard_label,
                latitude: alert.latitude.toFixed(6),
                longitude: alert.longitude.toFixed(6),
                proximity_to_area: (alert.proximity_to_area / 1000).toFixed(2),
                status: alert.status,
                jurisdiction_code: alert.jurisdiction_code,
              }
            });

            if (outStatus.includes(alert.status)) {
              outGraphics.push(pointGraphic);

            } else {
              graphics.push(pointGraphic);
            }
          });

          activeHazardLayer.applyEdits({
            addFeatures: graphics,
          });

          nonActiveHazardLayer.applyEdits({
            addFeatures: outGraphics,
          });
        });

      //--------- Areas of interest layer configuration ---------//
      const aoiLayer = mapConfig.configureAOILayer();

      API.get('hazNotificationsAPI', '/aois')
        .then(response => {
          let graphics = [];

          response.forEach(aoi => {
            const point = {
              type: "point",
              longitude: JSON.parse(aoi.location).coordinates[0],
              latitude: JSON.parse(aoi.location).coordinates[1]
            };

            const pointGraphic = new Graphic({
              geometry: point,
              attributes:
              {
                id: aoi.id,
                location: aoi.location,
                longitude: JSON.parse(aoi.location).coordinates[0].toFixed(6),
                latitude: JSON.parse(aoi.location).coordinates[1].toFixed(6),
                name: aoi.name,
                type: aoi.type,
                created_date: aoi.created_date,
              }
            });

            graphics.push(pointGraphic);
          })

          aoiLayer.applyEdits({
            addFeatures: graphics
          })
        });

      //--------- Buffer layer configuration ---------//
      const bufferLayer = mapConfig.configureBufferLayer();

      API.get('hazNotificationsAPI', '/buffers')
        .then(response => {
          let graphics = [];

          response.forEach(buffer => {
            let polyline = {
              type: "polyline",
              hasZ: false,
              hasM: false,
              paths: JSON.parse(buffer.area).coordinates
            };

            let polylineGraphic = new Graphic({
              geometry: polyline,
              attributes:
              {
                id: buffer.id,
                area: JSON.parse(buffer.area).coordinates,
                aoi_id: buffer.aoi_id,
                radius: buffer.radius / 1000,
              }
            });

            graphics.push(polylineGraphic);
          });

          bufferLayer.applyEdits({
            addFeatures: graphics,
          });
        });

      //--------- Hazard polygon extent layer configuration ---------//
      const extentLayer = mapConfig.configureHazardExtentLayer();

      API.get('hazNotificationsAPI', '/hazard-extents')
        .then(response => {
          let graphics = [];
          let polygon;

          response.forEach(extent => {
            polygon = {
              type: "polygon",
              hasZ: false,
              hasM: false,
              rings: JSON.parse(extent.extent_area).coordinates
            };

            let polygonGraphic = new Graphic({
              geometry: polygon,
              attributes:
              {
                id: extent.id,
                hazard_label: extent.hazard_label,
                hazard_type: extent.hazard_type,
                hazard_status: extent.hazard_status,
                size_class: extent.size_class,
                hazard_extent: extent.hazard_extent,
              }
            });

            graphics.push(polygonGraphic);
          });

          extentLayer.applyEdits({
            addFeatures: graphics,
          });
        });

      //--------- Evacuation polygon extent layer configuration ---------//
      const evacuationLayer = mapConfig.configureEvacuationExtentLayer();

      API.get('hazNotificationsAPI', '/evacuations')
        .then(response => {
          let graphics = [];
          let polygon;

          response.forEach(evacuation => {
            polygon = {
              type: "polygon",
              hasZ: false,
              hasM: false,
              rings: JSON.parse(evacuation.area).coordinates
            };

            let polygonGraphic = new Graphic({
              geometry: polygon,
              attributes:
              {
                id: evacuation.id,
                oaa_id: evacuation.oaa_id,
                hazard_type: evacuation.hazard_type,
                hazard_label: evacuation.hazard_label,
                status: evacuation.status,
                issuing_agency: evacuation.issuing_agency,
                area: evacuation.area,
              }
            });

            graphics.push(polygonGraphic);
          });

          evacuationLayer.applyEdits({
            addFeatures: graphics,
          });
        });

      //--------- Adding layers to map ---------//
      map.add(aoiLayer);
      map.add(activeHazardLayer);
      map.add(nonActiveHazardLayer);
      map.add(bufferLayer);
      map.add(extentLayer);
      map.add(evacuationLayer);

      //--------- Hazard feature table configuration ---------//
      configureFeatureTable(view, activeHazardLayer);

      //--------- Email zoom/highlight configuration ---------//
      if (urlParams != null) {
        if (typeof urlParams.aoi_name !== 'undefined' && typeof urlParams.hazard_label !== 'undefined') {
          settings.highlightFeaturePopup(view, activeHazardLayer, "hazard_label", urlParams.hazard_label);
          settings.highlightFeaturePopup(view, aoiLayer, "name", urlParams.aoi_name);

        } else if (typeof urlParams.aoi_name !== 'undefined') {
          settings.highlightFeaturePopup(view, aoiLayer, "name", urlParams.aoi_name);

        } else if (typeof urlParams.hazard_label !== 'undefined') {
          settings.highlightFeaturePopup(view, activeHazardLayer, "hazard_label", urlParams.hazard_label);
        }
      }

      //--------- Widget configuration ---------//
      configureWidget(view, activeHazardLayer, nonActiveHazardLayer, aoiLayer);
    }
  }, []);

  return (
    <div>
      <div id="main-map-container" ref={mapDiv}>
        <div
          id='toggle-table-btn'
          className='esri-icon-table esri-widget--button'
          role='button'
          title="Toggle Table"></div>
      </div>
    </div>
  );
}

export default Maps;